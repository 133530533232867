import React from 'react'

const About = () => {
  return (
    <section className="section about-section">
      <h1 className="section-title">About us</h1>
      <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum facere eligendi doloribus tempora, laudantium sunt, illo voluptate vitae non incidunt quisquam inventore nam, officia provident. Sunt saepe porro reiciendis beatae!
      </p>
    </section>
  )
}

export default About
